<template>
    <div class="course-content">
        <div class="course-head">
            课程首页编辑
        </div>
        <div class="form-content">
            <div class="main-box">
                <el-form :model="courseInfo" :rules="rules" ref="projectForm" label-position="top" label-width="100px"
                         class="subject-ruleForm">
                    <el-scrollbar class="subject-content">
                        <el-form-item label="内容介绍:" prop="contnet_description">
                            <vue-tinymce
                                    v-model="courseInfo.contnet_description"
                                    :setting="setting"/>
                        </el-form-item>
                        <el-form-item label="教学标准:" prop="jx_standard">
                            <vue-tinymce
                                    v-model="courseInfo.jx_standard"
                                    :setting="setting"/>
                        </el-form-item>
                        <el-form-item label="资源类型:" prop="resource_type">
                            <vue-tinymce
                                    v-model="courseInfo.resource_type"
                                    :setting="setting"/>
                        </el-form-item>
                        <el-form-item label="微课简介:" prop="wk_introductory">
                            <vue-tinymce
                                    v-model="courseInfo.wk_introductory"
                                    :setting="setting"/>
                        </el-form-item>
                        <el-form-item label="实训简介:" prop="sx_introductory">
                            <vue-tinymce
                                    v-model="courseInfo.sx_introductory"
                                    :setting="setting"/>
                        </el-form-item>
                        <el-form-item label="相关课程:" prop="correlation_course" class="relation-course">
                            <div class="course-list">
                                <el-checkbox-group v-model="courseInfo.correlation_course">
                                    <el-checkbox-button v-for="item in courseList" :label="item.course_id"
                                                        :key="item.course_id">{{item.course_name}}
                                    </el-checkbox-button>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
<!--                        暂时隐藏，耀明说显示的鱼骨图和知识图谱不好看，后面修改插件样式再放开-->
<!--                        <el-form-item label="鱼骨图名称:" prop="fish_name" class="fish-title">-->
<!--                            <el-input-->
<!--                                    type="text"-->
<!--                                    placeholder="请输入内容"-->
<!--                                    v-model="courseInfo.fish_name"-->
<!--                                    maxlength="10"-->
<!--                                    show-word-limit-->
<!--                            ></el-input>-->
<!--                        </el-form-item>-->
<!--                        <div class="add-btn">-->
<!--                            <div class="btn-box" @click="addFishTitle"><i class="el-icon-plus"-->
<!--                                                                          style="margin-right: 8px;"></i>添加标题-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <el-form-item label="鱼骨图:" prop="fishbone_map">-->
<!--                            <el-table-->
<!--                                    class="fishTable"-->
<!--                                    ref="fishTable"-->
<!--                                    :data="courseInfo.fishbone_map"-->
<!--                                    style="width: 100%;margin-bottom: 20px;"-->
<!--                                    row-key="id"-->
<!--                                    border-->
<!--                                    default-expand-all-->
<!--                                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',height:'50px',padding:'0 200px 0 60px',borderRight:'none'}"-->
<!--                                    :cell-style="{fontSize: '12px',color: '#333',height:'50px',padding:'0 100px 0 60px',borderRight:'none'}"-->
<!--                                    :tree-props="{children: 'children'}">-->
<!--                                <el-table-column prop="name" min-width="100%" label="名称" align="left"></el-table-column>-->
<!--                                <el-table-column label="操作" min-width="50%" align="right">-->
<!--                                    <template slot-scope="scope">-->
<!--                                        <el-button type="text" size="small" v-if="scope.row.level !== 3"-->
<!--                                                   @click="addFishChild(scope.row)">-->
<!--                                            <i class="el-icon-plus" style="margin-right: 6px;"/>添加子标题-->
<!--                                        </el-button>-->
<!--                                        <el-button type="text" size="small" @click="editFishTitle(scope.row)">-->
<!--                                            <i class="el-icon-edit" style="margin-right: 6px;"/>编辑-->
<!--                                        </el-button>-->
<!--                                        <el-button type="text" style="color: #EB1515" size="small"-->
<!--                                                   @click="deleteFishRow(scope.row)">-->
<!--                                            <i class="el-icon-delete" style="margin-right: 6px;"/>删除-->
<!--                                        </el-button>-->
<!--                                        <a class="icon-btn" v-if="scope.row.level !== 3">-->
<!--                                             <span class="actions-btn" v-if="scope.row.expand"-->
<!--                                                   @click="expandFish(scope.row,false)">-->
<!--                                                <i class="el-icon-caret-top" style="margin-right: 4px;"></i>收起-->
<!--                                             </span>-->
<!--                                            <span class="actions-btn" v-else @click="expandFish(scope.row,true)">-->
<!--                                                <i class="el-icon-caret-bottom" style="margin-right: 4px;"></i>展开-->
<!--                                                </span>-->
<!--                                        </a>-->
<!--                                    </template>-->
<!--                                </el-table-column>-->
<!--                            </el-table>-->
<!--                        </el-form-item>-->
<!--                        <div class="add-btn">-->
<!--                            <div class="btn-box" @click="addKnowledgeTitle"><i class="el-icon-plus"-->
<!--                                                                               style="margin-right: 8px;"></i>添加标题-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <el-form-item label="知识图谱:" prop="knowledge_map">-->
<!--                            <el-table-->
<!--                                    class="knowledgeTable"-->
<!--                                    ref="knowledgeTable"-->
<!--                                    :data="courseInfo.knowledge_map"-->
<!--                                    style="width: 100%;margin-bottom: 20px;"-->
<!--                                    row-key="id"-->
<!--                                    border-->
<!--                                    default-expand-all-->
<!--                                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',height:'50px',padding:'0 200px 0 60px',borderRight:'none'}"-->
<!--                                    :cell-style="{fontSize: '12px',color: '#333',height:'50px',padding:'0 100px 0 60px',borderRight:'none'}"-->
<!--                                    :tree-props="{children: 'children'}">-->
<!--                                <el-table-column prop="name" min-width="100%" label="名称" align="left"></el-table-column>-->
<!--                                <el-table-column label="操作" min-width="50%" align="right">-->
<!--                                    <template slot-scope="scope">-->
<!--                                        <el-button type="text" size="small" v-if="scope.row.level !== 3"-->
<!--                                                   @click="addKnowledgeChild(scope.row)">-->
<!--                                            <i class="el-icon-plus" style="margin-right: 6px;"/>添加子标题-->
<!--                                        </el-button>-->
<!--                                        <el-button type="text" size="small" @click="editKnowledgeTitle(scope.row)">-->
<!--                                            <i class="el-icon-edit" style="margin-right: 6px;"/>编辑-->
<!--                                        </el-button>-->
<!--                                        <el-button type="text" style="color: #EB1515" size="small"-->
<!--                                                   @click="deleteKnowledgeRow(scope.row)">-->
<!--                                            <i class="el-icon-delete" style="margin-right: 6px;"/>删除-->
<!--                                        </el-button>-->
<!--                                        <a class="icon-btn" v-if="scope.row.level !== 3">-->
<!--                                             <span class="actions-btn" v-if="scope.row.expand"-->
<!--                                                   @click="expandKnowledge(scope.row,false)">-->
<!--                                                <i class="el-icon-caret-top" style="margin-right: 4px;"></i>收起-->
<!--                                             </span>-->
<!--                                            <span class="actions-btn" v-else @click="expandKnowledge(scope.row,true)">-->
<!--                                                <i class="el-icon-caret-bottom" style="margin-right: 4px;"></i>展开-->
<!--                                                </span>-->
<!--                                        </a>-->
<!--                                    </template>-->
<!--                                </el-table-column>-->
<!--                            </el-table>-->
<!--                        </el-form-item>-->
                        <div class="add-btn">
                            <div class="btn-box" @click="addFileTitle"><i class="el-icon-plus"
                                                                          style="margin-right: 8px;"></i>添加标题
                            </div>
                            <div class="notice-title">(上传文件格式为pdf、图片文件和视频文件）</div>
                            <input
                                    ref="fileInput"
                                    type="file"
                                    id="fileInput"
                                    @change="uploadFile($event)"
                                    style="display:none"
                                    accept="*"
                            />
                        </div>
                        <el-form-item label="上传资料:" prop="files_data">
                            <el-table
                                    class="fileTable"
                                    ref="fileTable"
                                    :data="courseInfo.files_data"
                                    style="width: 100%;margin-bottom: 20px;"
                                    border
                                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',height:'50px',padding:'0 60px 0 60px',borderRight:'none'}"
                                    :cell-style="{fontSize: '12px',color: '#333',height:'50px',padding:'0 60px 0 60px',borderRight:'none'}">
                                <el-table-column prop="name" min-width="100%" label="名称" align="left">
                                    <template slot-scope="scope">
                                        <div class="file-name-box">{{scope.row.name}}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="fileName" min-width="100%" label="文件" align="left">
                                    <template slot-scope="scope">{{scope.row.file_name}}</template>
                                </el-table-column>
                                <el-table-column prop="progress" min-width="70%" label="上传进度"
                                                 align="center">
                                    <template slot-scope="scope" v-if="scope.row.percentage">
                                        <el-progress :stroke-width="9" :percentage="scope.row.percentage"
                                                     :status="scope.row.percentage === 100?'success':null"
                                                     color="#409EFF"></el-progress>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" min-width="100%" align="center">
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.id > 14" type="text" style="color: #409EFF;"
                                                   size="small"
                                                   @click="editFileRow(scope.row)">
                                            <i class="el-icon-edit" style="margin-right: 6px;"/>编辑
                                        </el-button>
                                        <el-button v-if="scope.row.id > 14" type="text" style="color: #EB1515"
                                                   size="small"
                                                   @click="deleteFileRow(scope.row)">
                                            <i class="el-icon-delete" style="margin-right: 6px;"/>删除
                                        </el-button>
                                        <el-button type="text" size="small" @click="uploadFileRow(scope.row)">
                                            <i class="iconfont" style="margin-right: 6px;">&#xe659;</i>上传
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item>
                            <div class="footer-box">
                                <el-button type="primary" @click="saveCourse('projectForm')">保 存</el-button>
                                <el-button @click="resetCourse('projectForm')">重 置</el-button>
                                <el-button @click="backTo">返 回</el-button>
                            </div>
                        </el-form-item>
                    </el-scrollbar>
                </el-form>
            </div>
        </div>
        <CourseInsideTitle v-if="dialogVisible" :dialogVisible="dialogVisible" :title="dialogTitle"
                           :dialogLevel="dialogLevel" :editTitle="editTitle"
                           @closeDialog="closeDialog" @saveData="saveData"></CourseInsideTitle>
    </div>
</template>

<script>
    import CourseInsideTitle from "../../components/createCourse/CourseInsideTitle";

    export default {
        name: "qualityCourseAdd",
        components: {
            CourseInsideTitle
        },
        data() {
            return {
                dialogVisible: false,
                dialogTitle: "",//弹框的标题
                dialogType: "",//判断类型，鱼骨图、知识图谱、上传资料
                dialogLevel: "",
                editTitle: "",
                editId: "",
                fishRowIndex: 0,
                fishChildIndex: 0,
                fishEndIndex: 0,
                knowledgeRowIndex: 0,
                knowledgeChildIndex: 0,
                knowledgeEndIndex: 0,
                fileRowIndex: 0,
                courseInfo: {
                    fish_name: "",
                    course_id: "",//课程id
                    contnet_description: "",//内容介绍
                    jx_standard: "",//教学标准
                    resource_type: "",//资源类型
                    wk_introductory: "",//微课简介
                    sx_introductory: "",//实训简介
                    correlation_course: [],//相关课程
                    fishbone_map: [],//鱼骨图json
                    knowledge_map: [],//知识图谱json
                    files_data: [{
                        id: 1,
                        name: '课程视频导读'
                    },{
                        id: 2,
                        name: '鱼骨图'
                    },{
                        id: 3,
                        name: '知识图谱'
                    },{
                        id: 4,
                        name: '课程教学整体设计'
                    }, {
                        id: 5,
                        name: '授课计划表'
                    }, {
                        id: 6,
                        name: '随堂学习手册'
                    }, {
                        id: 7,
                        name: '课程考核方案'
                    }, {
                        id: 8,
                        name: '教学大纲'
                    }, {
                        id: 9,
                        name: '教学评价方案'
                    }, {
                        id: 10,
                        name: '课程标准'
                    }, {
                        id: 11,
                        name: '教学实施报告'
                    }, {
                        id: 12,
                        name: '阶段性评教表'
                    }, {
                        id: 13,
                        name: '学生课堂情况反馈表'
                    }, {
                        id: 14,
                        name: '项目全过程评分表'
                    },],//文件json
                },
                formInfo: {},
                rules: {
                    // contnet_description: [
                    //     {required: true, message: '请输入内容介绍', trigger: 'blur'},
                    // ],
                    // jx_standard: [
                    //     {required: true, message: '请输入教学标准', trigger: 'blur'},
                    // ],
                    // resource_type: [
                    //     {required: true, message: '请输入资源类型', trigger: 'blur'},
                    // ],
                    // wk_introductory: [
                    //     {required: true, message: '请输入微课简介', trigger: 'blur'},
                    // ],
                    // sx_introductory: [
                    //     {required: true, message: '请输入实训简介', trigger: 'blur'},
                    // ],
                    // correlation_course: [
                    //     {required: true, message: '请输入相关课程', trigger: 'blur'},
                    // ],
                    // fishbone_map: [
                    //     {required: true, message: '请输入鱼骨图内容', trigger: 'blur'},
                    // ],
                    // knowledge_map: [
                    //     {required: true, message: '请输入知识图谱内容', trigger: 'blur'},
                    // ],
                    // files_data: [
                    //     {required: true, message: '请上传资料', trigger: 'blur'},
                    // ],
                    // fish_name: [
                    //     {required: true, message: '请输入鱼骨图名称', trigger: 'blur'},
                    //     {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    // ],
                },
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300
                },
                courseList: [],//课程列表
            }
        },
        mounted() {
            this.getCourse();
            this.courseInfo.course_id = this.$route.query.course_id;
            if (this.courseInfo.course_id !== undefined || this.courseInfo.course_id !== '') {
                this.getCourseInfo();
            }
        },
        methods: {
            getCourseInfo() {
                this.$http.axiosGetBy(this.$api.contents_showCourseContent, {course_id: this.courseInfo.course_id}, res => {
                    if (res.code == 200) {
                        this.courseInfo.contnet_description = res.data.contnet_description;
                        this.courseInfo.jx_standard = res.data.jx_standard;
                        this.courseInfo.resource_type = res.data.resource_type;
                        this.courseInfo.wk_introductory = res.data.wk_introductory;
                        this.courseInfo.sx_introductory = res.data.sx_introductory;
                        this.courseInfo.correlation_course = res.data.correlation_course.split(',').map(Number);
                        if(res.data.fishbone_map != ''){
                            this.courseInfo.fishbone_map = JSON.parse(res.data.fishbone_map);
                        }
                        if(res.data.knowledge_map !=''){

                            this.courseInfo.knowledge_map = JSON.parse(res.data.knowledge_map);
                        }
                        this.courseInfo.files_data = res.data.files_data;
                        this.formatFishMap();
                        this.formatFilsData();
                    } else {
                        //没有就是要新建
                    }
                })
            },
            formatFishMap() {
                let len = this.courseInfo.fishbone_map.length;
                if (len > 0) {
                    this.courseInfo.fish_name = this.courseInfo.fishbone_map[len - 1].name;
                    this.courseInfo.fishbone_map.splice(len - 1, 1)
                }
            },
            //判断有无文件
            formatFilsData() {
                this.courseInfo.files_data.forEach(item => {
                    let index = this.courseInfo.files_data.indexOf(item);
                    item.id = index + 1;
                    if (item.file_path) {
                        item.percentage = 100;
                        if (item.file_path.src) {
                            item.file_path = item.file_path.url;
                        }
                    }
                })
            },
            getCourse() {
                this.$http.axiosGet(this.$api.courses, res => {
                    if (res.code == 200) {
                        this.courseList = res.list;
                    }
                })
            },
            expandFish(row, val) {
                if (row.children.length === 0) return;
                this.$refs.fishTable.toggleRowExpansion(row, val);
                row.expand = val;
            },
            expandKnowledge(row, val) {
                if (row.children.length === 0) return;
                this.$refs.knowledgeTable.toggleRowExpansion(row, val);
                row.expand = val;
            },
            getId(list, arr) {
                list.forEach(ele => {
                    arr.push(ele.id);
                    if (ele.children && ele.children.length > 0) {
                        this.getId(ele.children, arr)
                    }
                    if (ele.children.children && ele.children.children.length > 0) {
                        this.getId(ele.children, arr)
                    }
                })
                return arr;
            },
            saveEditFish(obj) {
                if (this.dialogLevel == 1) {
                    this.courseInfo.fishbone_map[this.fishRowIndex].name = obj.name;
                } else if (this.dialogLevel == 2) {
                    this.courseInfo.fishbone_map[this.fishRowIndex].children[this.fishChildIndex].name = obj.name;
                } else {
                    this.courseInfo.fishbone_map[this.fishRowIndex].children[this.fishChildIndex].children[this.fishEndIndex].name = obj.name;
                }
            },
            saveFishTitle(obj) {
                let arr = [];
                let maxId = 0;
                if (this.courseInfo.fishbone_map.length > 0) {
                    arr = this.getId(this.courseInfo.fishbone_map, arr);
                    maxId = Math.max(...arr);
                }
                let newId = maxId + 1;
                obj.id = newId;
                if (this.dialogLevel == 1) {
                    obj.level = 1;
                    obj.parent_id = 0;
                    this.courseInfo.fishbone_map.push(obj);
                } else if (this.dialogLevel == 2) {
                    obj.level = 2;
                    obj.parent_id = this.courseInfo.fishbone_map[this.fishRowIndex].id;
                    this.courseInfo.fishbone_map[this.fishRowIndex].expand = true;
                    this.courseInfo.fishbone_map[this.fishRowIndex].children.push(obj);
                } else {
                    obj.level = 3;
                    obj.parent_id = this.courseInfo.fishbone_map[this.fishRowIndex].children[this.fishChildIndex].id;
                    this.courseInfo.fishbone_map[this.fishRowIndex].children[this.fishChildIndex].expand = true;
                    this.courseInfo.fishbone_map[this.fishRowIndex].children[this.fishChildIndex].children.push(obj);
                }
            },
            addFishChild(row) {
                this.dialogTitle = '鱼骨图';
                this.dialogVisible = true;
                this.dialogType = 1;
                this.dialogLevel = row.level === 1 ? 2 : 3;
                this.getFishId(row);
            },
            editFishTitle(row) {
                this.dialogTitle = '鱼骨图';
                this.dialogVisible = true;
                this.dialogType = 1;
                this.dialogLevel = row.level;
                this.editTitle = row.name;
                this.editId = row.id;
                this.getFishId(row);
            },
            deleteFishRow(row) {
                this.dialogType = 1;
                this.dialogLevel = row.level;
                this.getFishId(row);
                if (row.level === 1) {
                    this.courseInfo.fishbone_map.splice(this.fishRowIndex, 1);
                } else if (row.level === 2) {
                    this.courseInfo.fishbone_map[this.fishRowIndex].children.splice(this.fishRowIndex, 1);
                } else {
                    this.courseInfo.fishbone_map[this.fishRowIndex].children[this.fishChildIndex].children.splice(this.fishEndIndex, 1);
                }
            },
            getFishId(row) {
                if (row.level === 1) {
                    this.fishRowIndex = this.courseInfo.fishbone_map.indexOf(row);
                } else if (row.level === 2) {
                    this.courseInfo.fishbone_map.forEach(item => {
                        if (item.id == row.parent_id) {
                            this.fishRowIndex = this.courseInfo.fishbone_map.indexOf(item);
                        }
                    })
                    this.fishChildIndex = this.courseInfo.fishbone_map[this.fishRowIndex].children.indexOf(row);
                } else {
                    let len = this.courseInfo.fishbone_map.length;
                    for (let i = 0; i < len; i++) {
                        for (let j = 0; j < this.courseInfo.fishbone_map[i].children.length; j++) {
                            if (this.courseInfo.fishbone_map[i].children[j].id == row.parent_id) {
                                this.fishRowIndex = i;
                                this.fishChildIndex = j;
                                this.fishEndIndex = this.courseInfo.fishbone_map[i].children[j].children.indexOf(row);
                            }
                        }
                    }
                }
            },
            addFishTitle() {
                this.dialogTitle = '鱼骨图';
                this.dialogVisible = true;
                this.dialogType = 1;
                this.dialogLevel = 1;
            },
            saveEditKnowledge(obj) {
                if (this.dialogLevel == 1) {
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].name = obj.name;
                } else if (this.dialogLevel == 2) {
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children[this.knowledgeChildIndex].name = obj.name;
                } else {
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children[this.knowledgeChildIndex].children[this.knowledgeEndIndex].name = obj.name;
                }
            },
            saveKnowledgeTitle(obj) {
                let arr = [];
                let maxId = 0;
                if (this.courseInfo.knowledge_map.length > 0) {
                    arr = this.getId(this.courseInfo.knowledge_map, arr);
                    maxId = Math.max(...arr);
                }
                let newId = maxId + 1;
                obj.id = newId;
                if (this.dialogLevel == 1) {
                    obj.level = 1;
                    obj.parent_id = 0;
                    this.courseInfo.knowledge_map.push(obj);
                } else if (this.dialogLevel == 2) {
                    obj.level = 2;
                    obj.parent_id = this.courseInfo.knowledge_map[this.knowledgeRowIndex].id;
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].expand = true;
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children.push(obj);
                } else {
                    obj.level = 3;
                    obj.parent_id = this.courseInfo.knowledge_map[this.knowledgeRowIndex].children[this.knowledgeChildIndex].id;
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children[this.knowledgeChildIndex].expand = true;
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children[this.knowledgeChildIndex].children.push(obj);
                }
            },
            addKnowledgeChild(row) {
                this.dialogTitle = '知识图谱';
                this.dialogVisible = true;
                this.dialogType = 2;
                this.dialogLevel = row.level === 1 ? 2 : 3;
                this.getKnowlegeId(row);
            },
            editKnowledgeTitle(row) {
                this.dialogTitle = '知识图谱';
                this.dialogVisible = true;
                this.dialogType = 2;
                this.dialogLevel = row.level;
                this.editTitle = row.name;
                this.editId = row.id;
                this.getKnowlegeId(row);
            },
            deleteKnowledgeRow(row) {
                this.dialogType = 1;
                this.dialogLevel = row.level;
                this.getKnowlegeId(row);
                if (row.level === 1) {
                    this.courseInfo.knowledge_map.splice(this.knowledgeRowIndex, 1);
                } else if (row.level === 2) {
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children.splice(this.knowledgeChildIndex, 1);
                } else {
                    this.courseInfo.knowledge_map[this.knowledgeRowIndex].children[this.knowledgeChildIndex].children.splice(this.knowledgeEndIndex, 1);
                }
            },
            getKnowlegeId(row) {
                if (row.level === 1) {
                    this.knowledgeRowIndex = this.courseInfo.knowledge_map.indexOf(row);
                } else if (row.level === 2) {
                    this.courseInfo.knowledge_map.forEach(item => {
                        if (item.id == row.parent_id) {
                            this.knowledgeRowIndex = this.courseInfo.knowledge_map.indexOf(item);
                        }
                    })
                    this.knowledgeChildIndex = this.courseInfo.knowledge_map[this.knowledgeRowIndex].children.indexOf(row);
                } else {
                    let len = this.courseInfo.knowledge_map.length;
                    for (let i = 0; i < len; i++) {
                        for (let j = 0; j < this.courseInfo.knowledge_map[i].children.length; j++) {
                            if (this.courseInfo.knowledge_map[i].children[j].id == row.parent_id) {
                                this.knowledgeRowIndex = i;
                                this.knowledgeChildIndex = j;
                                this.knowledgeEndIndex = this.courseInfo.knowledge_map[i].children[j].children.indexOf(row);
                            }
                        }
                    }
                }
            },
            addKnowledgeTitle() {
                this.dialogTitle = '知识图谱';
                this.dialogVisible = true;
                this.dialogType = 2;
                this.dialogLevel = 1;
            },
            saveFileTitle(obj) {
                //只能再新增三个，因为首页最多显示十六个图标
                if (this.courseInfo.files_data.length > 14) {
                    this.$message.warning('新增数量超过上限！')
                    return;
                }
                let arr = [];
                let maxId = 0;
                if (this.courseInfo.files_data.length > 0) {
                    this.courseInfo.files_data.forEach(item => {
                        arr.push(item.id)
                    })
                    maxId = Math.max(...arr);
                }
                let newId = maxId + 1;
                obj.id = newId;
                this.courseInfo.files_data.push(obj);
            },
            saveEditFile(obj) {
                this.courseInfo.files_data[this.fileRowIndex].name = obj.name;
            },
            editFileRow(row) {
                this.fileRowIndex = this.courseInfo.files_data.indexOf(row);
                this.dialogTitle = '资料';
                this.dialogVisible = true;
                this.dialogType = 3;
                this.editTitle = row.name;
                this.editId = row.id;
                this.dialogLevel = 1;
            },
            deleteFileRow(row) {
                let deleteIndex = this.courseInfo.files_data.indexOf(row);
                this.courseInfo.files_data.splice(deleteIndex, 1)
            },
            uploadFileRow(row) {
                this.fileRowIndex = this.courseInfo.files_data.indexOf(row);
                this.$refs.fileInput.click();
            },
            //解决一个文件不能多传的问题
            resetFile() {
                this.$refs.fileInput.setAttribute('type', 'text');
                this.$refs.fileInput.setAttribute('type', 'file');
            },
            uploadFile(event) {
                let fileList = event.target.files[0];
                let modelName = event.target.files[0].name;
                // let limiteFileType = ['pdf'];
                // if (
                //     !limiteFileType.includes(
                //         modelName.split(".")[modelName.split(".").length - 1].toLowerCase()
                //     )
                // ) {
                //     this.$message.warning("文件格式错误！");
                //     return;
                // }
                let tmp = this.courseInfo.files_data[this.fileRowIndex];
                let config = {
                    onUploadProgress: progressEvent => {
                        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                        tmp.percentage = complete;
                        this.$set(this.courseInfo.files_data, this.fileRowIndex, tmp);
                    }
                };
                tmp.file_name = modelName;
                let formData = new FormData();
                formData.append('file', fileList);
                this.$fileHttp.axiosFileConfig(this.$api.contents_uploads, formData, config, (res) => {
                    if (res.code === 200) {
                        tmp.file_path = res.data.url;
                        this.$message.success(res.msg);
                        this.resetFile();
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            addFileTitle() {
                this.dialogTitle = '资料';
                this.dialogVisible = true;
                this.dialogType = 3;
                this.dialogLevel = 1;
            },
            saveData(data) {
                let obj = {
                    name: data,
                    expand: false,
                    children: []
                }
                if (this.dialogType == 1) {
                    if (this.editId !== '') {
                        this.saveEditFish(obj);
                    } else {
                        this.saveFishTitle(obj);
                    }
                } else if (this.dialogType == 2) {
                    if (this.editId !== '') {
                        this.saveEditKnowledge(obj)
                    } else {
                        this.saveKnowledgeTitle(obj)
                    }
                } else {
                    delete obj.children;
                    if (this.editId !== '') {
                        this.saveEditFile(obj)
                    } else {
                        this.saveFileTitle(obj)
                    }
                }
                this.closeDialog(false);
            },
            closeDialog(val) {
                this.dialogTitle = '';
                this.dialogVisible = val;
                this.dialogType = '';
                this.dialogLevel = '';
                this.editTitle = '';
                this.editId = '';
            },
            saveCourse(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let arr = [];
                        this.courseInfo.files_data.forEach(item => {
                            let obj = item;
                            delete obj.id;
                            if (obj.percentage) {
                                delete obj.percentage;
                            }
                            arr.push(obj)
                        })
                        this.formInfo = JSON.parse(JSON.stringify(this.courseInfo));
                        delete this.formInfo.fish_name;
                        if (this.courseInfo.fish_name != '') {
                            let newObj = {
                                name: this.courseInfo.fish_name
                            }
                            this.formInfo.fishbone_map.push(newObj);
                        }
                        this.formInfo.files_data = JSON.stringify(arr);
                        this.formInfo.fishbone_map = JSON.stringify(this.formInfo.fishbone_map);
                        this.formInfo.knowledge_map = JSON.stringify(this.formInfo.knowledge_map);
                        this.formInfo.correlation_course = this.formInfo.correlation_course.join(',');
                        //没有这个id就是新增
                        if (this.courseInfo.course_id === undefined || this.courseInfo.course_id === '') {
                            this.$store.dispatch('setCourseEditInfo', this.formInfo);
                            this.$router.push({
                                path: '/admin/courseManage/qualityCourseCreate',
                                query: {
                                    id: 0
                                }
                            });
                        } else {
                            this.$http.axiosPost(this.$api.contents_saveCourseHome, this.formInfo, res => {
                                if (res.code == 200) {
                                    this.backTo();
                                    this.$message.success(res.msg)
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetCourse(formName) {
                this.$refs[formName].resetFields();
            },
            backTo() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .course-content {
        width: 100%;
        height: 100%;
        position: relative;

        .course-head {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            background: #409EFF;
            color: #ffffff;
            font-size: 18px;
            border-bottom-left-radius: 60% 50%;
            border-bottom-right-radius: 60% 50%;
        }

        .form-content {
            height: calc(100% - 20px);
            z-index: 0;
            width: calc(100% - 160px);
            margin: 0 auto;
            background: #ffffff;

            .main-box {
                width: 100%;
                height: calc(100% - 80px);
                padding-top: 80px;

                .subject-ruleForm {
                    height: 100%;

                    .subject-content {
                        flex: 1;
                        height: 100%;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                            height: calc(100% - 60px);
                        }

                        ::v-deep .el-scrollbar__view {
                            padding: 0 100px;
                            min-width: 1300px;
                            height: 100%;
                        }

                        .relation-course {
                            display: flex;

                            ::v-deep .el-form-item__label {
                                width: 300px;
                            }
                        }

                        .fish-title {
                            display: flex;

                            ::v-deep .el-form-item__label {
                                width: 90px;
                            }
                        }

                        .course-list {
                            ::v-deep .el-checkbox-group {
                                display: flex;
                                flex-wrap: wrap;
                            }

                            ::v-deep .el-checkbox-button {
                                width: calc(20% - 20px);
                                margin-bottom: 18px;
                                border-radius: 20px;
                            }

                            ::v-deep .el-checkbox-button__inner {
                                border: unset;
                                border-radius: 20px;
                                border: 1px solid #DCDFE6;
                                max-width: 250px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
                                box-shadow: 0px 0 0 0 #8cc5ff;
                            }

                        }

                        .add-btn {
                            position: relative;

                            .notice-title {
                                font-size: 14px;
                                color: #EB1515;
                                position: absolute;
                                left: 80px;
                                top: 10px;
                            }

                            .btn-box {
                                width: 100px;
                                height: 30px;
                                background: #409EFF;
                                border: 1px solid #DCECFE;
                                border-radius: 4px;
                                color: #ffffff;
                                text-align: center;
                                line-height: 2;
                                cursor: pointer;
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }

                        .icon-btn {
                            margin-left: 10px;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .fishTable {
                            .el-button--text {
                                font-size: 14px;
                            }
                        }

                        .file-name-box {
                            width: 150px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .footer-box {
                            display: flex;
                            justify-content: center;

                            .el-button {
                                margin-right: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>